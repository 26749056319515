import React, { useState, useContext, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import useSettings from "../../hooks/useSettings";
import usePlans from "../../hooks/usePlans";
import { toast } from "react-toastify";
import { openApi } from "../../services/api";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import "./style.css";
import ColorModeContext from "../../layout/themeContext";
import { Helmet } from "react-helmet";
import IconButton from "@material-ui/core/IconButton";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";

const Login = () => {
  const [user, setUser] = useState({ email: "", password: "" });
  const [userCreation, setUserCreation] = useState(false);
  const { getPublicSetting } = useSettings();
  const { handleLogin } = useContext(AuthContext);
  const { getPlanList } = usePlans();
  const [plans, setPlans] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();

  const { appLogoLight, appLogoDark, primaryColorLight, primaryColorDark, mode, appName, appLogoFavicon, colorMode } = useContext(ColorModeContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const values = Object.fromEntries(formData.entries());
    try {
      await openApi.post("/auth/signup", values);
      toast.success(i18n.t("signup.toasts.success"));
      history.push("/login");
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    getPublicSetting("userCreation")
      .then((data) => {
        setUserCreation(data === "enabled");
      })
      .catch((error) => {
        console.log("Erro ao ler a configuração", error);
      });

    const fetchData = async () => {
      const planList = await getPlanList({ listPublic: "false" });
      setPlans(planList);
    };

    fetchData();
  }, [getPublicSetting, getPlanList]);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", mode);
  }, [mode]);

  return (
    <>
      <Helmet>
        <title>{appName || "Default App Name"}</title>
        <link rel="icon" href={appLogoFavicon || "/default-favicon.ico"} />
      </Helmet>
      <IconButton className="toggle-icon" onClick={colorMode.toggleColorMode}>
        {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
      <div className={`container ${isActive ? "active" : ""}`} style={{ borderColor: mode === "light" ? primaryColorLight : primaryColorDark }}>
        <div className="toggle-container">
          <div className="toggle">
            <div className="toggle-panel toggle-left">
              <h1>Seja bem-vindo(a)</h1>
              <span>Caso já tenha uma conta, faça o seu login.</span>
              <button className="hidden" onClick={() => setIsActive(false)}>
                Entrar
              </button>
            </div>
            <div className="toggle-panel toggle-right">
              <h1>Simples e acessível</h1>
              <span>Caso ainda não tenha cadastro...</span>
              {userCreation && (
                <button className="hidden" onClick={() => setIsActive(true)}>
                  Cadastrar-se
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="form-container sign-in">
          <form onSubmit={handleSubmitLogin}>
            <h1>Acesso</h1>
            <img src={mode === "light" ? appLogoLight : appLogoDark} alt="Logo" className="logoImg" />
            <span>Inicie sua jornada...</span>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={user.email}
              onChange={handleChangeInput}
              required
              autoComplete="email"
              autoFocus
            />
            <input
              type="password"
              placeholder="Senha"
              name="password"
              value={user.password}
              onChange={handleChangeInput}
              required
              autoComplete="current-password"
            />
            <button type="submit" className="login-button">
              Acessar
            </button>
          </form>
        </div>
        <div className="form-container sign-up">
          <form onSubmit={handleSignUp}>
            <h1>Cadastro</h1>
            <span>use seu melhor email...</span>
            <input type="text" placeholder="Empresa" name="companyName" required />
            <input type="text" placeholder="Nome" name="name" required />
            <input type="email" placeholder="Email" name="email" required />
            <input type="password" placeholder="Senha" name="password" required />
            <input type="text" placeholder="Telefone" name="phone" required />
            <select name="planId" required>
              <option value="">Plano</option>
              {plans.map((plan) => (
                <option key={plan.id} value={plan.id}>
                  {plan.name} - R$ {plan.amount}
                </option>
              ))}
            </select>
            <button type="submit" className="signup-button">
              Cadastrar-se
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
