import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Can } from "../Can";
import { makeStyles } from "@material-ui/core/styles";
import {
  History,
  PictureAsPdf,
  Replay,
  SwapHorizOutlined,
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import usePlans from "../../hooks/usePlans";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import ConfirmationModal from "../ConfirmationModal";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import SettingsIcon from "@material-ui/icons/Settings";
import Button from "@material-ui/core/Button";
import TransferTicketModalCustom from "../TransferTicketModalCustom";
import AcceptTicketWithouSelectQueue from "../AcceptTicketWithoutQueueModal";
import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

//icones
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import UndoIcon from "@material-ui/icons/Undo";
import PowerIcon from "@material-ui/icons/Power";
import PowerOffIcon from "@material-ui/icons/PowerOff";
import ScheduleModal from "../ScheduleModal";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import CheckIcon from "@material-ui/icons/Check";
import ShowTicketOpen from "../ShowTicketOpenModal";
import { toast } from "react-toastify";
import useCompanySettings from "../../hooks/useSettings/companySettings";
import ShowTicketLogModal from "../../components/ShowTicketLogModal";
import TicketMessagesDialog from "../TicketMessagesDialog";
import { Menu, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    maxWidth: "100%",
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    flexShrink: 0,
    display: "flex",
    position: "relative",
    zIndex: 1300,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  botoes: {
    display: "flex",
    padding: "15px",
    justifyContent: "flex-end",
    maxWidth: "100%",
    // alignItems: "center"
  },
  menuIcon: {
    color: theme.mode === "light" ? theme.palette.primary.main : "#FFF",
  },
  menuButton: {
    padding: 2,
    margin: "0 auto",
    marginRight: "5px",
  },
}));

const SessionSchema = Yup.object().shape({
  ratingId: Yup.string().required("Avaliação obrigatória"),
});

const TicketActionButtonsCustom = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isMounted, setIsMounted] = useState(true);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const { setCurrentTicket } = useContext(TicketsContext);
  const [open, setOpen] = React.useState(false);
  const formRef = React.useRef(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [acceptAudioMessage, setAcceptAudio] = useState(
    ticket.contact.acceptAudioMessage
  );
  const [
    acceptTicketWithouSelectQueueOpen,
    setAcceptTicketWithouSelectQueueOpen,
  ] = useState(false);
  const [showTicketLogOpen, setShowTicketLogOpen] = useState(false);
  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);

  const [showSchedules, setShowSchedules] = useState(false);
  const [enableIntegration, setEnableIntegration] = useState(
    ticket.useIntegration
  );

  const [openAlert, setOpenAlert] = useState(false);
  const [userTicketOpen, setUserTicketOpen] = useState("");
  const [queueTicketOpen, setQueueTicketOpen] = useState("");
  const [logTicket, setLogTicket] = useState([]);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const { get: getSetting } = useCompanySettings();
  const { getPlanCompany } = usePlans();

  useEffect(() => {
    fetchData();

    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      setIsMounted(false);
    };
  }, []);

  const fetchData = async () => {
    const companyId = user.companyId;
    const planConfigs = await getPlanCompany(undefined, companyId);
    setShowSchedules(planConfigs.plan.useSchedules);
    setOpenTicketMessageDialog(false);

    setShowTicketLogOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleClickOpen = async (e) => {
    const setting = await getSetting({
      column: "requiredTag",
    });

    if (setting?.requiredTag === "enabled") {
      //verificar se tem uma tag
      try {
        const contactTags = await api.get(`/contactTags/${ticket.contact.id}`);
        if (!contactTags.data.tags) {
          toast.warning(i18n.t("messagesList.header.buttons.requiredTag"));
        } else {
          setOpen(true);
          // handleUpdateTicketStatus(e, "closed", user?.id);
        }
      } catch (err) {
        toastError(err);
      }
    } else {
      setOpen(true);
      // handleUpdateTicketStatus(e, "closed", user?.id);
    }
  };

  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setLoading(false);
  };
  const handleOpenAcceptTicketWithouSelectQueue = async () => {
    setAcceptTicketWithouSelectQueueOpen(true);
  };

  const handleOpenScheduleModal = () => {
    if (typeof handleClose == "function") handleClose();
    setContactId(ticket.contact.id);
    setScheduleModalOpen(true);
  };

  const handleCloseScheduleModal = () => {
    setScheduleModalOpen(false);
    setContactId(null);
  };

  const handleOpenTransferModal = (e) => {
    setTransferTicketModalOpen(true);
    if (typeof handleClose == "function") handleClose();
  };

  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
    if (typeof handleClose == "function") handleClose();
  };

  const handleCloseTicketWithoutFarewellMsg = async () => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: "closed",
        userId: user?.id || null,
        sendFarewellMessage: false,
        amountUsedBotQueues: 0,
      });

      setLoading(false);
      history.push("/tickets");
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleEnableIntegration = async () => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        useIntegration: !enableIntegration,
      });
      setEnableIntegration(!enableIntegration);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleShowLogTicket = async () => {
    setShowTicketLogOpen(true);
  };

  const handleContactToggleAcceptAudio = async () => {
    try {
      const contact = await api.put(
        `/contacts/toggleAcceptAudio/${ticket.contact.id}`
      );
      setAcceptAudio(contact.data.acceptAudioMessage);
    } catch (err) {
      toastError(err);
    }
  };

  const handleCloseTransferTicketModal = () => {
    setTransferTicketModalOpen(false);
  };

  const handleDeleteTicket = async () => {
    try {
      await api.delete(`/tickets/${ticket.id}`);
      history.push("/tickets");
    } catch (err) {
      toastError(err);
    }
  };

  const handleSendMessage = async (id) => {
    let setting;

    try {
      setting = await getSetting({
        column: "greetingAcceptedMessage",
      });
    } catch (err) {
      toastError(err);
    }
    const msg = `${setting.greetingAcceptedMessage}`; //`{{ms}} *{{name}}*, ${i18n.t("mainDrawer.appBar.user.myName")} *${user?.name}* ${i18n.t("mainDrawer.appBar.user.continuity")}.`;
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: `${msg.trim()}`,
    };
    try {
      await api.post(`/messages/${id}`, message);
    } catch (err) {
      toastError(err);
    }
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
      });

      let setting;

      try {
        setting = await getSetting({
          column: "sendGreetingAccepted",
        });
      } catch (err) {
        toastError(err);
      }

      if (
        setting?.sendGreetingAccepted === "enabled" &&
        (!ticket.isGroup || ticket.whatsapp?.groupAsTicket === "enabled") &&
        ticket.status === "pending"
      ) {
        handleSendMessage(ticket.id);
      }

      // if (isMounted.current) {
      setLoading(false);
      // }
      if (status === "open" || status === "group") {
        setCurrentTicket({ ...ticket, code: "#" + status });
        // handleSelectTicket(ticket);
        setTimeout(() => {
          history.push("/tickets");
        }, 0);

        console.log("passou");
        setTimeout(() => {
          history.push(`/tickets/${ticket.uuid}`);
        }, 10);
      } else {
        setCurrentTicket({ id: null, code: null });
        history.push("/tickets");
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleAcepptTicket = async (id) => {
    setLoading(true);
    try {
      const otherTicket = await api.put(`/tickets/${id}`, {
        status: ticket.isGroup ? "group" : "open",
        userId: user?.id,
      });
      if (otherTicket.data.id !== ticket.id) {
        if (otherTicket.data.userId !== user?.id) {
          setOpenAlert(true);
          setUserTicketOpen(otherTicket.data.user.name);
          setQueueTicketOpen(otherTicket.data.queue.name);
        } else {
          setLoading(false);
          // handleSelectTicket(otherTicket.data);

          history.push(`/tickets/${otherTicket.data.uuid}`);
        }
      } else {
        // if (isMounted.current) {
        setLoading(false);
        // }

        // handleSelectTicket(ticket);
        history.push("/tickets");
        setTimeout(() => {
          history.push(`/tickets/${ticket.uuid}`);
        }, 1000);
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  return (
    <>
      {openAlert && (
        <ShowTicketOpen
          isOpen={openAlert}
          handleClose={handleCloseAlert}
          user={userTicketOpen}
          queue={queueTicketOpen}
        />
      )}
      {acceptTicketWithouSelectQueueOpen && (
        <AcceptTicketWithouSelectQueue
          modalOpen={acceptTicketWithouSelectQueueOpen}
          onClose={(e) => setAcceptTicketWithouSelectQueueOpen(false)}
          ticketId={ticket.id}
          ticket={ticket}
        />
      )}
      {showTicketLogOpen && (
        <ShowTicketLogModal
          isOpen={showTicketLogOpen}
          handleClose={(e) => setShowTicketLogOpen(false)}
          ticketId={ticket.id}
        />
      )}
      {openTicketMessageDialog && (
        <TicketMessagesDialog
          open={openTicketMessageDialog}
          handleClose={() => setOpenTicketMessageDialog(false)}
          ticketId={ticket.id}
        />
      )}
      <div className={classes.actionButtons}>
        {/* Aceitar Sem Fila */}
        {ticket.status === "pending" &&
          (ticket.queueId === null || ticket.queueId === undefined) && (
            <IconButton className={classes.menuButton}>
              <Tooltip title={i18n.t("messagesList.header.buttons.accept")}>
                <CheckIcon
                  onClick={(e) => handleOpenAcceptTicketWithouSelectQueue()}
                  className={classes.menuIcon}
                />
              </Tooltip>
            </IconButton>
          )}
        {/* Aceitar Com Fila */}
        {ticket.status === "pending" && ticket.queueId !== null && (
          <IconButton className={classes.menuButton}>
            <Tooltip title={i18n.t("messagesList.header.buttons.accept")}>
              <CheckIcon
                onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
                className={classes.menuIcon}
              />
            </Tooltip>
          </IconButton>
        )}
        {/* Reabrir Sem Fila */}
        {ticket.status === "closed" &&
          (ticket.queueId === null || ticket.queueId === undefined) && (
            <IconButton
              className={classes.menuButton}
              onClick={(e) => handleOpenAcceptTicketWithouSelectQueue()}
            >
              <Tooltip title={i18n.t("messagesList.header.buttons.reopen")}>
                <Replay className={classes.menuIcon} />
              </Tooltip>
            </IconButton>
          )}
        {/* Reabrir Com Fila */}
        {ticket.status === "closed" && ticket.queueId !== null && (
          <IconButton
            className={classes.menuButton}
            onClick={(e) => handleAcepptTicket(ticket.id)}
          >
            <Tooltip title={i18n.t("messagesList.header.buttons.reopen")}>
              <Replay className={classes.menuIcon} />
            </Tooltip>
          </IconButton>
        )}
        {(ticket.status === "open" || ticket.status === "group") && (
          <>
            {/* Resolver */}
            <IconButton className={classes.menuButton}>
              <Tooltip title={i18n.t("messagesList.header.buttons.resolve")}>
                <HighlightOffIcon
                  className={classes.menuIcon}
                  onClick={handleClickOpen}
                />
              </Tooltip>
            </IconButton>

            {/* Devolver */}
            <IconButton className={classes.menuButton}>
              <Tooltip title={i18n.t("tickets.buttons.returnQueue")}>
                <UndoIcon
                  className={classes.menuIcon}
                  onClick={(e) => handleUpdateTicketStatus(e, "pending", null)}
                />
              </Tooltip>
            </IconButton>

            {/* Transferir */}
            <IconButton className={classes.menuButton}>
              <Tooltip title="Transferir Ticket">
                <SwapHorizOutlined
                  className={classes.menuIcon}
                  onClick={handleOpenTransferModal}
                />
              </Tooltip>
            </IconButton>
          </>
        )}
        <IconButton
          className={classes.menuButton}
          aria-controls="simple-menu"
          aria-haspopup="true"
          color="primary"
          onClick={handleMenuClick}
          style={{ marginRight: "15px" }}
        >
          <Tooltip title="AÇÕES">
            <SettingsIcon className={classes.menuIcon} />
          </Tooltip>
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={isMenuOpen}
          onClose={handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {/* Exportar para PDF */}
          <MenuItem onClick={() => setOpenTicketMessageDialog(true)}>
            <PictureAsPdf
              className={classes.menuIcon}
              style={{ marginRight: "5px" }}
            />
            {i18n.t("ticketsList.buttons.exportAsPDF")}
          </MenuItem>

          {/* Logs Conversa */}
          <MenuItem onClick={handleShowLogTicket}>
            <History
              className={classes.menuIcon}
              style={{ marginRight: "5px" }}
            />
            {i18n.t("messagesList.header.buttons.logTicket")}
          </MenuItem>

          {/* Integrações */}
          {ticket.status === "open" || ticket.status === "group" ? (
            <MenuItem onClick={handleEnableIntegration}>
              {enableIntegration ? (
                <PowerIcon style={{ color: "green", marginRight: "5px" }} />
              ) : (
                <PowerOffIcon style={{ color: "red", marginRight: "5px" }} />
              )}
              {i18n.t("messagesList.header.buttons.enableIntegration")}
            </MenuItem>
          ) : null}

          {/* Aceitar Audio */}
          {ticket.status === "open" || ticket.status === "group" ? (
            <MenuItem onClick={() => handleContactToggleAcceptAudio()}>
              {acceptAudioMessage ? (
                <MicIcon style={{ color: "green", marginRight: "5px" }} />
              ) : (
                <MicOffIcon style={{ color: "red", marginRight: "5px" }} />
              )}
              {i18n.t("ticketOptionsMenu.acceptAudioMessage")}
            </MenuItem>
          ) : null}

          {/* Excluir */}
          {ticket.status === "open" || ticket.status === "group" ? (
            <Can
              role={user.profile}
              perform="ticket-options:deleteTicket"
              yes={() => (
                <MenuItem onClick={handleOpenConfirmationModal}>
                  <DeleteOutlineIcon
                    className={classes.menuIcon}
                    style={{ marginRight: "5px" }}
                  />
                  {i18n.t("tickets.buttons.deleteTicket")}
                </MenuItem>
              )}
            />
          ) : null}

          {confirmationOpen && (
            <ConfirmationModal
              title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")} #${
                ticket.id
              }?`}
              open={confirmationOpen}
              onClose={setConfirmationOpen}
              onConfirm={handleDeleteTicket}
            >
              {i18n.t("ticketOptionsMenu.confirmationModal.message")}
            </ConfirmationModal>
          )}
          {transferTicketModalOpen && (
            <TransferTicketModalCustom
              modalOpen={transferTicketModalOpen}
              onClose={handleCloseTransferTicketModal}
              ticketid={ticket.id}
              ticket={ticket}
            />
          )}
          {scheduleModalOpen && (
            <ScheduleModal
              open={scheduleModalOpen}
              onClose={handleCloseScheduleModal}
              aria-labelledby="form-dialog-title"
              contactId={contactId}
            />
          )}
        </Menu>
      </div>
      <Formik
        enableReinitialize={true}
        validationSchema={SessionSchema}
        innerRef={formRef}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            actions.setSubmitting(false);
            actions.resetForm();
          }, 400);
        }}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Form>
              <DialogActions className={classes.botoes}>
                <Button
                  onClick={(e) => handleCloseTicketWithoutFarewellMsg()}
                  style={{ background: "#065183", color: "white" }}
                >
                  {i18n.t("messagesList.header.dialogRatingWithoutFarewellMsg")}
                </Button>

                <Button
                  onClick={(e) =>
                    handleUpdateTicketStatus(
                      e,
                      "closed",
                      user?.id,
                      ticket?.queue?.id
                    )
                  }
                  style={{ background: "#065183", color: "white" }}
                >
                  {i18n.t("messagesList.header.dialogRatingCancel")}
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default TicketActionButtonsCustom;
