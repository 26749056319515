import React, { useState, useEffect } from "react";
import {
  Avatar,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";

const TicketInfo = ({ contact, ticket, onClick }) => {
  const [maxChars, setMaxChars] = useState(50);

  const updateMaxChars = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 420) {
      setMaxChars(9);
    } else if (screenWidth < 450) {
      setMaxChars(16);
    } else if (screenWidth < 500) {
      setMaxChars(20);
    } else if (screenWidth < 600) {
      setMaxChars(25);
    } else if (screenWidth < 650) {
      setMaxChars(30);
    } else if (screenWidth < 700) {
      setMaxChars(35);
    } else {
      setMaxChars(50);
    }
  };

  useEffect(() => {
    updateMaxChars();
    window.addEventListener("resize", updateMaxChars);

    return () => {
      window.removeEventListener("resize", updateMaxChars);
    };
  }, []);

  // Função para truncar strings longas e adicionar "..." no final
  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  const renderCardReader = () => {
    return (
      <Tooltip title={contact.name} placement="top-start" arrow>
        <CardHeader
          onClick={onClick}
          style={{ cursor: "pointer", height: "55px", marginLeft: "-20px" }}
          titleTypographyProps={{ noWrap: true }}
          subheaderTypographyProps={{ noWrap: true }}
          avatar={
            <Avatar
              style={{ height: 40, width: 40, borderRadius: 20 }}
              src={contact.profilePicUrl}
              alt="contact_image"
            />
          }
          title={
            <Typography noWrap>
              {truncateString(contact.name, maxChars)}
            </Typography>
          }
          subheader={`Conversa Nº ${ticket.id}`}
        />
      </Tooltip>
    );
  };

  return (
    <Grid container alignItems="center" spacing={10}>
      <Grid item xs={6}>
        {renderCardReader()}
      </Grid>
    </Grid>
  );
};

export default TicketInfo;
